import React from 'react';


function App() {
    return (
        <div className="App">
            <h1>Hello World!!</h1>
            <p>This is a simple web page created with React!</p>
            <p>This is my cat!</p>
            <img src="./images/cat.jpeg" height="500px" alt={"cat"}/>
            <p>Creator: Angel Curras Sanchez</p>
        </div>
    );
}

export default App;
